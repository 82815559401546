// import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import HeaderStyled from "./styles/HeaderStyles"
import NavigationStyled from "./styles/NavigationStyles"
import MobileNavigationStyled from "./styles/MobileNavigationStyles"
import { useStaticQuery, graphql } from "gatsby"
import Link from "gatsby-link"
// import AniLink from "gatsby-plugin-transition-link/AniLink"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressPage(title: { eq: "header" }) {
        acf {
          header_logo {
            source_url
          }
          header_top_banner
          header_phone {
            title
            url
            target
          }
          header_email {
            title
            url
            target
          }
          header_nav {
            link {
              target
              title
              url
            }
          }
        }
      }
    }
  `)
  const {
    header_logo,
    header_top_banner,
    header_phone,
    header_email,
    header_nav,
  } = data.wordpressPage.acf

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onscroll = function() {
        if (window.pageYOffset === 0) {
          setIsScrolled(false)
        } else {
          setIsScrolled(true)
        }
      }
    }
  })

  const [navOpen, setNavOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  return (
    <HeaderStyled className={isScrolled ? "scrolled" : ""}>
      <div className="container">
        <div className="header">
          <div className="desktop">
            <div className="logo">
              <Link to="/">
                <img src={header_logo.source_url} />
              </Link>
            </div>
            <div className="header__left">
              <p className="contact">
                Zadzwoń <a href={header_phone.url}>{header_phone.title}</a> lub
                napisz <a href={header_email.url}>{header_email.title}</a>
              </p>
              <NavigationStyled className={`nav__wrapper`}>
                <nav className="nav">
                  <ul>
                    {header_nav.map((item, i) => (
                      <li key={`nav__${i}`}>
                        <Link activeClassName="active" to={item.link.url}>
                          {item.link.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </NavigationStyled>
            </div>
          </div>
          <div className="mobile">
            <div className="logo">
              <Link to="/">
                <img src={header_logo.source_url} />
              </Link>
            </div>
            <p className="contact">
              Zadzwoń <a href={header_phone.url}>{header_phone.title}</a> lub
              napisz <a href={header_email.url}>{header_email.title}</a>
            </p>
            <div
              className={`hamburger ${navOpen ? "active" : "not-active"}`}
              onClick={() => {
                setNavOpen(!navOpen)
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <MobileNavigationStyled className={`${navOpen ? "active" : ""}`}>
              <nav className="nav">
                <ul>
                  {header_nav.map((item, i) => (
                    <li key={`nav__${i}`}>
                      <Link activeClassName="active" to={item.link.url}>
                        {item.link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </MobileNavigationStyled>
          </div>
        </div>
      </div>
    </HeaderStyled>
  )
}

export default Header
