import styled from "styled-components"

const FormStyled = styled.div`
  padding-top: 20px;
  .form__row {
    display: flex;
    justify-content: space-between;
    @media (max-width: 540px) {
      flex-wrap: wrap;
    }
    & > div {
      max-width: calc(50% - 15px);
      @media (max-width: 540px) {
        max-width: 100%;
      }
    }
    * {
      width: 100%;
    }
    input {
      background: transparent;
      padding: 18px;
      color: #fff;
      border: 1px solid #7b89c3;
      &::placeholder {
        color: #7b89c3;
      }
    }
  }
  .form__input__wrapper {
    padding-bottom: 25px;
    &--full {
      max-width: 100% !important;
    }
    &--error {
      input {
        border: 1px solid #ef7e7e;
        &::placeholder {
          color: #ef7e7e;
        }
      }
      .error {
        padding-top: 4px;
        display: block;
        color: #ef7e7e;
      }
    }
  }
  .form__checkbox__wrapper {
    position: relative;
    padding-left: 50px;
    padding-top: 20px;
    &--error {
      input {
        & ~ span:before {
          border: 1px solid #ef7e7e !important;
        }
      }
      .error {
        padding-top: 0px;
        display: block;
        color: #ef7e7e;
      }
    }
    input {
      position: absolute;
      top: 30px;
      left: 10px;
      & ~ span:before {
        content: "";
        position: absolute;
        top: 20px;
        left: 0px;
        background: #031b7b;
        border: 1px solid #7b89c3;
        width: 30px;
        height: 30px;
        transition: 0.1s all;
      }
      &:checked ~ span:before {
        background: #7b89c3;
      }
    }
    a {
      color: rgb(5, 183, 186);
      text-decoration: none;
      transition: all 0.3s ease 0s;
      &:hover {
        opacity: 0.5;
      }
    }
    p {
      font-size: 0.625rem;
      line-height: 1.4;
    }
  }
  .form__textarea__wrapper {
    * {
      width: 100%;
    }
    textarea {
      background: transparent;
      padding: 18px;
      color: #fff;
      border: 1px solid #7b89c3;
      line-height: 1.5;
      &::placeholder {
        color: #7b89c3;
      }
    }
  }
  div[class*="ButtonFromStyles"] {
    margin-top: 30px;
  }
`

export default FormStyled
