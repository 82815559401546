/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Header from "./header"
import "./layout.css"
import GlobalStyle from "./styles/GlobalStyles"
import LoyautStyled from "./styles/LoyoutStyles"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import SEO from "./seo"

const Layout = ({ children, hideFooter }) => {
  return (
    <>
      <SEO />
      <LoyautStyled>
        <GlobalStyle />
        <Header />
        <main>{children}</main>
        <Footer hideFooter={hideFooter} />
      </LoyautStyled>
    </>
  )
}

export default Layout
