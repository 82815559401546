import styled from "styled-components"

const HeaderStyled = styled.header`
  z-index: 40;
  position: sticky;
  top: 0;
  &.scrolled {
    background: #191c1d;
    .header {
      padding: 20px 0px 20px;
    }
    .container {
      border-color: transparent;
    }
    .contact {
      @media (max-width: 940px) {
        top: 20px;
      }
    }
    .hamburger {
      @media (max-width: 540px) {
        bottom: 22px;
      }
    }
    div[class*="MobileNavigationStyles"] {
      padding-top: 90px;
    }
  }
  .logo {
    @media (max-width: 540px) {
      margin-top: 40px;
    }
    img {
      @media (max-width: 540px) {
        max-width: 140px;
      }
    }
  }
  .container {
    border-bottom: 1px solid #fff;
    transition: 0.3s all;
  }
  .header {
    padding: 50px 0 30px;
    transition: 0.3s all;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1180px) {
      padding: 30px 0 30px;
      position: relative;
    }
  }
  .desktop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 940px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media (max-width: 940px) {
      display: block;
    }
  }
  .contact {
    text-align: right;
    color: #fff;
    font-size: 14px;
    padding-bottom: 1rem;
    @media (max-width: 940px) {
      position: absolute;
      top: 30px;
      right: 0;
      padding-bottom: 0;
      z-index: 10;
    }
    @media (max-width: 680px) {
      font-size: 0;
    }
    a {
      color: #05b7ba;
      font-weight: 900;
      text-decoration: none;
      @media (max-width: 680px) {
        font-size: 1rem;
        margin: 0 5px;
      }
    }
  }
  .hamburger {
    position: absolute;
    width: 40px;
    right: 0;
    bottom: 20px;
    cursor: pointer;
    z-index: 6;
    @media (max-width: 540px) {
      bottom: 35px;
    }
    span {
      display: block;
      width: 100%;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
      /* border-radius: 3px; */
      height: 3px;
      background: #fff;
      transition: all 0.3s;
      position: relative;
      & + span {
        margin-top: 9px;
      }
    }
    &.not-active {
      span:nth-child(1) {
        animation: ease 0.7s top-2 forwards;
      }
      span:nth-child(2) {
        animation: ease 0.7s scaled-2 forwards;
      }
      span:nth-child(3) {
        animation: ease 0.7s bottom-2 forwards;
      }
    }
    &.active {
      span:nth-child(1) {
        animation: ease 0.7s top forwards;
      }
      span:nth-child(2) {
        animation: ease 0.7s scaled forwards;
      }
      span:nth-child(3) {
        animation: ease 0.7s bottom forwards;
      }
    }
  }
`

export default HeaderStyled
