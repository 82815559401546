import styled from "styled-components"

const ButtonFromStyled = styled.div`
  width: 190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => (props.position === "left" ? "margin-right: auto" : "")};
  ${props => (props.position === "right" ? "margin-left: auto" : "")};
  ${props => (props.position === "center" ? "margin: 0 auto" : "")};
  a,
  button {
    text-transform: uppercase;
    background: transparent;
    color: #fff;
    display: block;
    border: none;
    height: 100%;
    width: 100%;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s all;
    ${props =>
      props.background === "green" ? "background: #05B7BA; color: white;" : ""};
    &:hover {
      ${props => (props.background === "green" ? "opacity: 0.6" : "")};
    }
  }
`

export default ButtonFromStyled
