import React, { useState } from "react"
import GoogleMapReact from "google-map-react"
import Pin from "../../static/sygnet.png"

const AnyReactComponent = ({ text, triggerClick, state }) => (
  <div
    onClick={() => {
      //   console.log("asd")
      //   triggerClick(!state)
    }}
    style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
  >
    <img src={Pin} />
    {state ? (
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "calc( 100% + 10px)",
          width: "150px",
          padding: "5px",
          background: "#fff",
        }}
      >
        <p style={{ margin: "0", lineHeight: "1.5", fontSize: "13px" }}>
          <strong>Warszawa</strong>
        </p>
        <p style={{ margin: "0", lineHeight: "1.5", fontSize: "13px" }}>
          Polska
        </p>
        <a
          style={{ color: "#d00060" }}
          target="_blank"
          href="https://www.google.com/maps/place/DAPR/@52.2276647,21.0112129,19.43z/data=!4m13!1m7!3m6!1s0x471ecce74915a473:0xd2a707ba40286dee!2sMarsza%C5%82kowska+89,+00-693+Warszawa!3b1!8m2!3d52.2280473!4d21.0123162!3m4!1s0x0:0x1d9c3e6c8473b519!8m2!3d52.2275513!4d21.0113284"
        >
          Zobacz w Google Maps
        </a>
      </div>
    ) : null}
  </div>
)

const GoogleMap = ({ address, googleMapsApiKey }) => {
  const [windoInfo, setWindoInfo] = useState(false)

  const isClient = typeof window !== "undefined"
  const lat = parseFloat(address.lat)
  const lng = parseFloat(address.lng)
  return (
    <div className="google-map">
      {isClient && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapsApiKey }}
          defaultCenter={[lat, lng]}
          defaultZoom={15}
        >
          <AnyReactComponent
            state={windoInfo}
            triggerClick={setWindoInfo}
            lat={lat}
            lng={lng}
          ></AnyReactComponent>
        </GoogleMapReact>
      )}
    </div>
  )
}

export default GoogleMap
