import styled from "styled-components"

const CookieStyled = styled.div`
&.show{
    display: block;
}
    display: none;
  position: fixed;
  min-width: 100%;
  height: auto;
  z-index: 100000;
  font-size: 13px;
  line-height: 20px;
  bottom: 0;
  left: 0;
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  /* z-index: 110; */
  background: #031b7b;
  padding: 9px 0;
  .container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cookies__buttons{
    margin-left: 20px;
    cursor: pointer;
    padding: 6px 10px;
    display: inline-block;
    text-transform: uppercase;
    background: #05B7BA;
    transition: 0.3s all;
    &:hover{
        opacity: 0.8;
    }
}
  }
`

export default CookieStyled
