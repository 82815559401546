const myInitObject = {
  WpProtocol: "https",
  WpDominian: "handler.dapr.pl",
  Wpurl: "https://handler.dapr.pl/",
  errors: {
    default: "Pole wymagane",
    checkbox: "Proszę zaznaczyć zgodę",
  },
  checkboxTEXT: `
  <p>Zgodnie z art. 13 ust 1 i 2 RODO informujemy, że:</p>
  <p>Administratorem Państwa danych osobowych jest DAPR sp. z o.o. z siedzibą w Warszawie, adres korespondencyjny Żurawia 47, 00-680 Warszawa. Z Administratorem można się skontaktować poprzez e-mail: <a href="mailto:kontakt@dapr.pl">kontakt@dapr.pl</a> lub tel. (+48) 22 100 40 13. Państwa dane osobowe będą przetwarzane w celu udzielenia odpowiedzi na przesłaną wiadomość, a w szczególności w celu przedstawienia oferty na Państwa życzenie lub rozpatrzenia skierowanego wniosku. Ponadto informujemy, że przysługuje Państwu szereg praw wynikających z RODO, a w szczególności prawo do wniesienia sprzeciwu na działania Administratora. Więcej informacji o prawach, które Państwu przysługują oraz o sposobach przetwarzania można znaleźć w <a href="/Polityka-prywatności-DAPR-.pdf" target="_blank">polityce prywatności DAPR sp. z o.o.</a></p>`,
  thx: "Dziękujemy za wysłanie wiadomości.",
  checkboxNewsletter: `<p>Wyrażam zgodę na przetwarzanie i wykorzystanie mojego adres email, podanego w powyższym formularzu, przez DAPR Sp. z o.o., w celach marketingowych, w szczególności w celu wysyłki newsletteru i informacji ofertowych.</p>`,
}

export default myInitObject
