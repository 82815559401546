import styled from "styled-components"

const FooterStyled = styled.footer`
  background: #031b7b;
  /* margin-top: 100px; */
  .heading {
    color: #fff;
    font-size: 3rem;
    margin-bottom: 0;
    @media (max-width: 940px) {
      font-size: 2rem;
    }
  }
  .subheading {
    color: #fff;
    font-size: 3rem;
    line-height: 1.5;
    padding-bottom: 100px;
    @media (max-width: 1180px) {
      padding-bottom: 30px;
      padding-bottom: 50px;
    }
    @media (max-width: 940px) {
      font-size: 2rem;
    }
  }
  h3 {
    color: #05b7ba;
    font-size: 1.625rem;
    font-weight: 400;
    margin: 0;
    padding-bottom: 1rem;
  }
  p {
    font-size: 1rem;
    color: #fff;
    line-height: 1.625rem;
  }
  a {
    color: #05b7ba;
    text-decoration: none;
    transition: 0.3s all;
    &:hover {
      opacity: 0.5;
    }
  }
  .container {
    padding: 95px 0 100px;
    & > .row {
      & > .half {
        @media (max-width: 1180px) {
          width: 100%;
          padding-bottom: 50px;
          &:nth-of-type(2n) {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 1180px) {
      flex-wrap: wrap;
    }
    & > * {
      width: 100%;
    }
    .half {
      width: calc(50% - 25px);
      @media (max-width: 480px) {
        width: 100%;
        padding-bottom: 30px;
      }
    }
  }
  .google-map {
    margin-top: 30px;
    height: 440px;
    @media (max-width: 480px) {
      margin-top: 0px;
    }
  }
  .copy {
    .container {
      padding: 60px 0;
    }
    p {
      color: #191c1d;
      font-size: 12px;
      padding-bottom: 30px;
      &:last-of-type {
        padding-bottom: 0;
      }
    }
    a {
      color: #191c1d;
    }
  }
`

export default FooterStyled
