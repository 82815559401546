import React, { useState } from "react"
import { useForm } from "react-hook-form"
import ButtonFromStyled from "./styles/ButtonFromStyles"
import myInitObject from "../ultis/variable"
import FormStyled from "./styles/FormStyles"

const Form = () => {
  const { handleSubmit, register, errors } = useForm()
  const [sendingPass, setsendingPass] = useState(false)

  const onSubmit = (values, e) => {
    const message = document.querySelector("textarea").value
    const formData = new FormData()
    formData.append("firstName", values.firstName)
    formData.append("lastName", values.lastName)
    formData.append("email", values.email)
    formData.append("phone", values.phone)
    formData.append("title", values.title)
    formData.append("message", message)
    formData.append("checkboxRODO", values.RODO)

    return fetch(myInitObject.Wpurl + "contactForm/index.php", {
      method: "post",
      body: formData,
    }).then(response => {
      if (response.status === 200) {
        setsendingPass(true)
        e.target.reset()
      }
    })
  }

  return (
    <FormStyled>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__row">
          <div
            className={`form__input__wrapper ${
              errors.firstName ? "form__input__wrapper--error" : ""
            }`}
          >
            <label>
              <input
                type="text"
                name="firstName"
                placeholder="Imię"
                ref={register({
                  required: true,
                })}
              />
            </label>
            <span className="error">
              {errors.firstName && myInitObject.errors.default}
            </span>
          </div>
          <div
            className={`form__input__wrapper ${
              errors.lastName ? "form__input__wrapper--error" : ""
            }`}
          >
            <label>
              <input
                type="text"
                name="lastName"
                placeholder="Nazwisko"
                ref={register({
                  required: true,
                })}
              />
            </label>
            <span className="error">
              {errors.lastName && myInitObject.errors.default}
            </span>
          </div>
        </div>

        <div className="form__row">
          <div
            className={`form__input__wrapper ${
              errors.email ? "form__input__wrapper--error" : ""
            }`}
          >
            <label>
              <input
                type="email"
                name="email"
                placeholder="Adres e-mail"
                ref={register({
                  required: true,
                })}
              />
            </label>
            <span className="error">
              {errors.email && myInitObject.errors.default}
            </span>
          </div>
          <div
            className={`form__input__wrapper ${
              errors.phone ? "form__input__wrapper--error" : ""
            }`}
          >
            <label>
              <input
                type="text"
                name="phone"
                placeholder="Numer telefonu"
                ref={register({
                  required: true,
                })}
              />
            </label>
            <span className="error">
              {errors.phone && myInitObject.errors.default}
            </span>
          </div>
        </div>
        <div className="form__row">
          <div
            className={`form__input__wrapper form__input__wrapper--full ${
              errors.title ? "form__input__wrapper--error" : ""
            }`}
          >
            <label>
              <input
                type="text"
                name="title"
                placeholder="Temat wiadomości"
                ref={register({
                  required: true,
                })}
              />
            </label>
            <span className="error">
              {errors.title && myInitObject.errors.default}
            </span>
          </div>
        </div>
        <div className="form__textarea__wrapper">
          <label>
            <textarea name="message" placeholder="Treść wiadomości" />
          </label>
        </div>

        <div
          className={`form__checkbox__wrapper ${
            errors.RODO ? "form__checkbox__wrapper--error" : ""
          }`}
        >
          <label>
            <input
              type="checkbox"
              name="RODO"
              value="true"
              ref={register({
                validate: value => value === "true",
              })}
            />
            <span></span>
            <div
              dangerouslySetInnerHTML={{ __html: myInitObject.checkboxTEXT }}
            ></div>
          </label>
          <span className="error">
            {errors.RODO && myInitObject.errors.checkbox}
          </span>
        </div>
        <ButtonFromStyled background="green" position="right">
          <button type="submit">wyślij</button>
        </ButtonFromStyled>
        {sendingPass ? <p>{myInitObject.thx}</p> : ""}
      </form>
    </FormStyled>
  )
}

export default Form
