import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FooterStyled from "./styles/FooterStyles"
import GoogleMap from "./googleMaps"
import Form from "./form"
import Cookie from "./cookies"

const Footer = ({ hideFooter }) => {
  const data = useStaticQuery(graphql`
    query {
      wordpressPage(title: { eq: "footer" }) {
        acf {
          footer_heading
          footer_subheading
          footer_data_address_office
          footer_data_address_invoce
          footer_data_form
          footer_latitude
          footer_longitude
          footer_copy
        }
      }
    }
  `)

  const {
    footer_heading,
    footer_subheading,
    footer_data_address_office,
    footer_data_address_invoce,
    footer_data_form,
    footer_latitude,
    footer_longitude,
    footer_copy,
  } = data.wordpressPage.acf

  const address = {
    lat: footer_latitude,
    lng: footer_longitude,
  }
  const googleMapsApiKey = "AIzaSyDrYh8pDYJw20JMkEQcDEA22KtoenkXYUY"

  return (
    <>
      <FooterStyled>
        {!hideFooter ? (
          <div className="container" id="footer">
            <div className="row">
              <h2
                className="heading"
                dangerouslySetInnerHTML={{ __html: footer_heading }}
              ></h2>
              <p
                className="subheading"
                dangerouslySetInnerHTML={{ __html: footer_subheading }}
              ></p>
            </div>
            <div className="row">
              <div className="half">
                <div className="row">
                  <div
                    className="half"
                    dangerouslySetInnerHTML={{
                      __html: footer_data_address_office,
                    }}
                  ></div>
                  <div
                    className="half"
                    dangerouslySetInnerHTML={{
                      __html: footer_data_address_invoce,
                    }}
                  ></div>
                  <GoogleMap
                    address={address}
                    googleMapsApiKey={googleMapsApiKey}
                  />
                </div>
              </div>
              <div className="half">
                <div
                  dangerouslySetInnerHTML={{ __html: footer_data_form }}
                ></div>
                <Form />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="copy background__white">
          <div className="container">
            <div
              className="copy__wrapper"
              dangerouslySetInnerHTML={{ __html: footer_copy }}
            ></div>
          </div>
        </div>
      </FooterStyled>
      <Cookie />
    </>
  )
}

export default Footer
