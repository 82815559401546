import styled from "styled-components"

const LayoutStyled = styled.div`
  position: relative;
  /* overflow: hidden; */
  .background__white {
    background: #fff;
  }
  .background__dark {
    background: #191c1d;
    color: #fff;
  }
  .background__grey {
    background: #fafafa;
  }
  .background__blue {
    background: #031b7b;
    color: #fff;
  }
  .heading {
    &--2 {
      font-size: 48px;
      text-align: center;
      font-weight: 400;
      padding-bottom: 50px;
      @media (max-width: 1180px) {
        font-size: 40px;
        line-height: 38px;
        padding-bottom: 30px;
      }
      @media (max-width: 540px) {
        font-size: 35px;
        line-height: 30px;
        padding-bottom: 20px;
      }
      @media (max-width: 450px) {
        font-size: 30px;
        line-height: 28px;
      }
    }
    &--3 {
      padding: 50px 0;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      @media (max-width: 540px) {
        font-size: 20;
      }
    }
    &--1 {
      text-align: center;
      padding: 100px 0 70px;
      font-size: 16px;
      text-transform: uppercase;
      color: #05b7ba;
      font-weight: 600;
      position: relative;
      span {
        margin: 0 5px;
        display: inline-block;
      }
      a {
        color: #05b7ba;
        text-decoration: none;
      }
      &:after {
        content: "";
        position: absolute;
        display: block;
        left: 50%;
        bottom: 50px;
        height: 1px;
        width: 170px;
        background: #e0e0e0;
        transform: translateX(-50%);
      }
    }
  }
  .offer {
    [class*="RowFullStyles"] {
      padding-top: 0px;
    }
    [class*="HeadingStyles"] {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
`

export default LayoutStyled
