import styled from "styled-components"

const MobileNavigationStyled = styled.div`
  display: none;
  @media (max-width: 940px) {
    display: block;
    overflow: hidden;
    right: 0;
    top: 0;
    bottom: 0;
    background: #191c1d;
    position: fixed;
    width: 300px;
    transition: 0.3s all;
    max-width: 0px;
    /* max-width: 300px; */
    z-index: 3;
    padding-top: 110px;
  }
  nav {
    padding: 30px 30px 20px;
    overflow-y: auto;
    width: 300px;
    height: 100%;
  }
  ul {
    margin: 0;
    list-style: none;
  }
  li {
    margin: 5px;
  }
  a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    display: block;
    padding: 10px;
  }
  &.active {
    max-width: 300px;
  }
`
export default MobileNavigationStyled
