import styled from "styled-components"
const NavigationStyled = styled.div`
  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 0;
    margin-left: 0;
    li {
      margin-left: 20px;
      margin-bottom: 0;
      &:last-of-type {
        a {
          padding-right: 0;
        }
      }
    }
    a {
      text-decoration: none;
      color: #fff;
      padding: 8px;
      &.active {
        text-decoration: underline;
        text-underline-position: under;
      }
    }
  }
`
export default NavigationStyled
