import React, { useEffect, useState } from "react"
import CookieStyled from "./styles/CookieStyles"

export const CookieContext = React.createContext()

const Cookie = () => {
  const [hideCookie, setHideCookie] = useState(false)

  useEffect(() => {
    setHideCookie(localStorage.getItem("cookies"))
  }, [])

  return (
    <>
      <CookieStyled className={`${hideCookie ? "" : "show"}`}>
        <div className="container">
          <p className="cookies__para">
            Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym
            poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na
            ich użycie.
          </p>
          <div className="cookies__buttons">
            <span
              className="cookie--noe"
              onClick={() => {
                localStorage.setItem("cookies", true)
                setHideCookie(true)
              }}
            >
              Zgoda
            </span>
          </div>
        </div>
      </CookieStyled>
    </>
  )
}

export default Cookie
